import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: true,
  sgaServer: '/',
  // sgaServer: 'http://staging.shatteredglassaudio.com/',
  paypalButtonUrl: 'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=',
  siteKey: '6LdlgYUUAAAAAPcLCmemN_82CDYoraYGNhAhApxJ',
  secret: '6LdlgYUUAAAAAAz0x7fKuAXgwvzCl6rHxGJNEZEo',
  logLevel: NgxLoggerLevel.DEBUG,
  // siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  // secret: '6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe'
  paypalButtonIds: {
    'Kompresor': 'LA2PTPJ5RH5EJ',
    'Phoenix 2': 'FQ9QQ7WYVNGVW',
    'Inferno': 'B5FKDSAD9QCTQ',
    'Mini Cracked Glass': '5J2QK3R6DZ2X4',
    'Code Red': 'C8CGTZTWYHSQ2',
    'Code Red Free': 'KV4XG6UKP74EY',
    'Ace': 'MJC8B8RNATFBA',
    'SGA1566': '33HWHCDVF4736'
  }
};
