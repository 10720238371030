export class Product {
    id: number;
    name: string;
}

export class ProductInList extends Product {
    gearImage: string;
}

export class ProductInHomeList extends Product {
    image: string;
    description: string;
    textColor?: string;
}

export class ProductDetail extends Product {
    bannerImage: string;
    bannerTextColor?: string;
    heading?: string[];
    sectionNames: string[][]; // [[display name(, id)],...] id is needed only if name!=id (spaces, etc.)
    mainTheme?: string;
    altTheme?: string;
    sections: ProductDetailSection[];
    isFree?: boolean = false;
    appleAppStoreLink?: string;
    donateUrl?: string;
    price?: number;
}

export class ProductDetailSection {
    id: string;
    textImage?: TextImageProductDetailSection;
    featuresImages?: FeaturesImageProductDetailSection[];
    downloads?: DownloadProductDetailSection;
    systemRequirements?: SystemRequirementsProductDetailSection;
}

export class TextImageProductDetailSection {
    text?: ProductDetailSectionText;
    textPosition?: string;
    image?: string;
}

export class ProductDetailSectionText {
    title?: string;
    text?: string[];
    textList?: string[];
    // paragraphs?: ProductDetailSectionTextParagraph[];
    paragraphs?: string[][];
}

export class FeaturesImageProductDetailSection {
    divClass?: string;
    maxWidth?: string;
    imageClass?: string;
    title?: string;
    text?: string;
    image: string;
    features: ProductDetailSectionFeature[];
}

export class ProductDetailSectionFeature {
    x: string;
    y: string;
    text: string;
    placement?: string;
}

export class DownloadProductDetailSection {
    version: string;
    userManual: string;
    downloadNameMaxWidth?: string;
    downloadDetails: DownloadDetail[];
}

export class DownloadDetail {
    // oneColumn?: boolean;
    name: string;
    // type?: string;
    installers: string[][];
}

export class SystemRequirementsProductDetailSection {
    macOS: string;
    windows: string;
    macInterfaces: string;
    windowsInterfaces: string;

}

export class StoreItem extends Product {
    price: string;
    buttonId?: string;
    isUpgradeable?: boolean = false;
    upgradeFromName?: string;
    upgradeFromId?: string;
    image: string;
}

export class UpgradeRequest {
    constructor(
        public g_recaptcha_response: string,
        public customer_email: string,
        public txn_id: string,
    ) { }
}

export class UpgradeResponse {
    public hasError: boolean;
    public error?: string;
    public buttonId: string;
    public price: string;
}

export class LicenseRequest {
    constructor(
        public g_recaptcha_response: string,
        public customer_email: string,
        public txn_id: string,
    ) { }
}

export class LicenseResponse {
    public hasError: boolean;
    public error?: string;
    public file?: string;
    public license?: string;
}

export class SupportRequest {
    constructor(
        public g_recaptcha_response: string,
        public name: string,
        public email: string,
        public daw: string,
        public daw_ver: string,
        public os: string,
        public os_ver: string,
        public product: string,
        public subject: string,
        public description: string,
        public problem_type: string
    ) { }
}

export class SupportResponse {
    public hasError: boolean;
    public error?: string;
    public message?: string;
}

export class EmailTxnIdsRequest {
    constructor(
        public g_recaptcha_response: string,
        public customer_email: string,
    ) { }

}

export class EmailTxnIdsResponse {
    public hasError: boolean;
    public error?: string;
    public message?: string;
}
